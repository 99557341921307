<template>
  <alert-banner v-if="showBanner" id="alert-banner" class="boi-banner">
    BOI questions?
    <div
      class="pl-1 pr-1"
      @click="logBoiBannerInteraction('boi-faqs-page')"
    >
      <router-link to="/faq/621379ca-3fb0-40e4-be32-a95a2ef2e11f">
        Learn more about the new federal BOI requirements.
      </router-link>
    </div>
    You can also
    <div
      class="pl-1 pr-1"
      @click="logBoiBannerInteraction('hire-us-page')"
    >
      <router-link to="/hire-us">
        Hire us
      </router-link>
    </div>
    to file for you.
  </alert-banner>
</template>

<script>
import { mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'BoiFaqsBanner',
  components: {
    AlertBanner: () => import('@/components/shared/AlertBanner.vue'),
  },
  computed: {
    ...mapGetters('account', {
      websiteId: 'websiteId',
    }),
    ...mapGetters('session', {
      token: 'getToken',
    }),
    hideIfRoute() {
      const hiddenRoutes = [
        'stageline-required-info',
        'stageline-v2-required-info',
        'stageline-post-payment',
        'stageline-v2-post-payment',
        'verify-order',
      ]

      const hiddenSlideLayoutTypes = [
        'verifyOrder',
      ]

      return !!(hiddenRoutes.includes(this.$route.name)) || hiddenSlideLayoutTypes.includes(this.currentSlide?.layout_type)
    },
    targetedWebsites() {
      // These IDs are temporary. This file will be deleted at the end of the year.
      return [
        '5f1068f2-48cd-4df6-beb5-187a9e504106', // www.49dollarmontanaregisteredagent.com
        'e8c6f7ce-f775-4c43-8628-abc428fe1b1e', // www.floridaregisteredagent.net
        '537e2e96-9fb3-4bfe-baa0-503912a8d3ed', // www.virginiaregisteredagent.com
        '259dcc5e-2071-430d-a5bf-c33265cdc81b', // www.texasregisteredagent.net
      ].includes(this.websiteId)
    },
    showBanner() {
      return this.token &&
        this.targetedWebsites &&
        !this.hideIfRoute
    },
  },
  methods: {
    async logBoiBannerInteraction(location) {
      await createOrFindClientInteractionLog({
        category: 'page-interaction',
        subCategory: location,
        interaction: {
          type: 'button',
          action: 'redirect',
          name: 'vehicle-boi-faqs-banner',
        },
        incompleteLogFromToday: true,
      })
    },
  },
}

</script>
<style lang="scss">
$primary: #283d96;
$primary-dark: darken($primary, 10%);
$yellow: #fff0b0;

.alert-banner-component.boi-banner {
  margin-bottom: .5rem;
  background-color: $yellow;
  color: $primary-dark;

  a {
    color: $primary;
  }
}

@media print {
  .alert-banner-component.boi-banner {
    display: none;

    div {
      display: none
    }
  }
}
</style>
